import React, { useEffect } from "react";
import { createApolloFetch } from "apollo-fetch";
import { NetworkContext } from "context/NetworkContext";



export const useGraphql = (query, mapper, variables = {}, initRequest = false) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState({});
  const [mappedData, setMappedData] = React.useState({});

  const {
    NetworkCtx: { graphqlUrl: uri, cdnUrl },
  } = React.useContext(NetworkContext);
  const client = createApolloFetch({ uri });

  const makeRequest = async (reqvars) => {
    try {
      const resdata = await client({ query, variables: { ...variables, ...reqvars } });
      setLoading(false);
      setError(false);
      setData(resdata);
      console.log("chennai 1st try")
  
      if (mapper && resdata) {
        try {
          console.log(resdata, "chennai resData", data,"chennai data");
          console.log( (Object.keys(data)) ? ("true") : ("else"), "chennai ckecking");
          let mapped = mapper(resdata, cdnUrl);
          setMappedData(mapped);
          console.log("chennai mapped data ", mapped);
          // window.location.href="/cart"
          // console.log("chennai 2nd try ")
        } catch (error) {
          console.error("MAPPER ERROR", error);
          setError(true);
          setMappedData({});
          console.log("chennai 1st catch ")
        }
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      setData([]);
      setMappedData([]);
      console.log("chennai 2nd catch ")
    }
  };

//   if((Object.keys(data)?.data?.allTransSkuLists?.nodes?.length === 0)){
// window.location.reload();
//   } else {
  
//   }
  React.useEffect(() => {
   
    if (initRequest) makeRequest();
      // window.location.href="/cart"
  }, []);
  return { error, loading, data, mappedData, makeRequest };
};
