import React from 'react';
import ReactDOM from 'react-dom';
// import { hydrate,render } from 'react-dom'; // for SEO implementation - subha
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';



ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// ######## bof :: Code change for SEO - using react snap ######### - subha//
// const App1 = (<BrowserRouter><App /></BrowserRouter>);
// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//     console.log('Hydrating...');
//   hydrate(App1, rootElement);
// } else {
//     console.log('Rendering...');
//   render(App1, rootElement);
// }

// ######## eof :: Code change for SEO - using react snap ######### //


 