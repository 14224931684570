export const routes = {
  stylori: "/stylori",
  PricingPage: "/pricingPage",
  Cart: "/cart",
  Checkout: "/Checkout",
  Register: "/register",
  UserLogin: "/login",
  UserRegister: "/registers",
  HomePageStylori: "/",
  Account: `/account${"-" + window.location.pathname.split("-")[1]}`,
  Silver: "/stylorisilver",
  Faqs: "/faqs",
  Stories: "/stories",
  // Collection: "/collections",
  ProductCare: "/productcare",
  Shipping: "/deliveryreturns",
  PrivacyPolicy: "/privacy",
  // TermsConditions: "/termsconditions",
  AboutUs: "/aboutus",
  ForgotPassword: "/forgotpassword",
  ResetPassword: "/resetpassword",
  ChangePassword: "/changepassword",
  paymentsuccess: "/paymentsuccess",
  paymentfail: "/paymentfail",
  experiencesCard: "/experiencecard",
  paymenthidden: "/paymenthidden",
  Education: "/education",
  // ContactUs: "/contactus",
  HomePage: "/homes",
  SavingsPage: "/savingscheme",
  // ExperiencePage: "/experiences",
  AdvertisingPage: "/advertising",
  TempleWorkPage: "/temple-work",
  // NewsRoomPage: "/newsroom",
  AboutUsPage: "/aboutus",
  Return: "/return",
  Delivery: "/delivery",
  // ContactPage: "/contactus",
  StoreDetail: "/loc/:id",
  // CareersPage: "/careers",
  DigitalMarketing: "/careersDetail",
  Diamonds: "/solitaires-search",
  CollectionHomePage: "/collectionhome",
  rudramadeviBlog: "/rudramadeviBlog",
  // Blog: "/blog",
  Solitaires: "/solitaires",
  CollectionPage: "/collectionPage",
  CollectionFullPage: "/collection",
  AkshyaTritiya: "/akshyaTritiya",
  BridalCollection: "/bridalcollection",
  NecklaceMela: "/necklaceMela",
  Diwali2022: "/diwali2022",
  AboutUsNew: "/aboutUs",
  TermsAndConditions: "/termsAndConditions",
  Static: `/static${"-" + window.location.pathname.split("-")[1]}`,
  PrivacyDetails: "/privacy_Details",
  FaqDetails: "/faqDetails",
  BlogPage: "/blogNew",
  ReadMore: "/readMore",
  ProductCareNew: "/productCareNew",
  CareerPage: "/careerPage",
  CustomApplication: "/form",
  BirthStones: "/birthStones"
};

export default routes;
